

// Colors
@import 'colors';


// Header -----------------------------------------------------------------------

header.blog-header {
    background: linear-gradient(145deg, $primary 35%, $secondary 100%);
    color: #fff;
}
header.blog-header a {
    color: #fff;
    text-decoration: none;
}
header.blog-header a h1 {
    font-size: 36px;
    font-weight: 600;
    transition: all 0.2s ease-in-out 0s;
}
header.blog-header a:hover h1 {
    padding-left: 10px;
}


// Nav -----------------------------------------------------------------------

nav.blog-nav .nav-link-pink:hover {
    color: $pink !important;
    text-decoration: underline;
}
nav.blog-nav .navbar-brand {
    display: none;
    color: #333;
    font-size: 13px;
    font-weight: bold;
}




// Category -----------------------------------------------------------------------

section.category .title span {
    background-color: $pink;
    color: #fff;
    padding: 5px 30px;
    border-radius: 50px;
    font-size: 30px;
    font-weight: 600;
}




// Post -----------------------------------------------------------------------

section.post .full-category span {
    background-color: $pink;
    color: #fff;
    padding: 5px 15px;
    border-radius: 20px;
}
section.post .title {
    font-size: 36px;
    font-weight: 600;
}
section.post .full-image {
    width: 100%;
    border-radius: 10px 10px 0 0;
}
section.post .description {
    color: #333;
}
section.post .description img {
    max-width: 500px;
}






// Mobile 

@media (max-width: 576px) {


    // Header -----------------------------------------------------------------------

    header.blog-header {
        text-align: center;
    }
    header.blog-header a h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    header.blog-header a:hover h1 {
        padding-left: 0;
    }
    header.blog-header .text {
        font-size: 11px;
    }

    // Nav -----------------------------------------------------------------------

    nav.blog-nav .nav-item-category {
        display: none;
    }
    nav.blog-nav .navbar-brand {
        display: block;
    }

        
    // Category -----------------------------------------------------------------------

    section.category .title span {
        font-size: 20px;
    }

    // Post -----------------------------------------------------------------------

    section.post .title {
        font-size: 20px;
    }
    section.post .description {
        padding: 15px;
    }
    section.post .item-post {
        margin-bottom: 20px;
    }
    section.post .last-posts h3 {
        text-align: center;
    }
    section.post .description img {
        max-width: 100%;
    }

}